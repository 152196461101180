import React, { Fragment, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button/Button'
import AddVolumeType from './AddVolumeType'
import Text from 'core/elements/Text'
import VolumeBackendConfigurationCards from './VolumeBackendConfigurationCards'
import { without, update } from 'ramda'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import TextField from 'core/components/validatedForm/TextField'

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
  },
  emptyHeader: {
    borderBottom: `1px solid ${theme.components.table.border}`,
  },
  header: {
    borderBottom: `1px solid ${theme.components.table.border}`,
    padding: '4px 8px',
    marginBottom: 8, // special for storage volume type table
  },
  cell: {
    padding: '8px 8px 16px',
  },
  sameLineCell: {
    display: 'grid',
    gap: 8,
    alignItems: 'center',
    gridTemplateColumns: 'max-content auto',
  },
  remove: {
    color: theme.components.badge.primary.color,
    cursor: 'pointer',
  },
}))

export default function StorageVolumeTypeTable({ params, updateParams, getParamsUpdater }) {
  const classes = useStyles()

  const removeVolumeTypeFn = useCallback(
    (type) => {
      updateParams({ volumeTypes: without([type], params.volumeTypes) })
    },
    [params.volumeTypes],
  )

  const updateVolumeTypeName = useCallback(
    (name, oldName, idx) => {
      // update both the volume type name as well as the property
      // in the associated volume backend configs
      const updatedVolumeBackendConfigs = params.volumeBackendConfigs.map((config) => {
        return config.volumeType === oldName
          ? {
              ...config,
              volumeType: name,
            }
          : config
      })
      updateParams({
        volumeTypes: update(idx, name, params.volumeTypes),
        volumeBackendConfigs: updatedVolumeBackendConfigs,
      })
    },
    [params.volumeTypes, params.volumeBackendConfigs],
  )

  return (
    <div className={classes.table}>
      <div className={classes.header}>
        <Text variant="caption1">Volume Type</Text>
      </div>
      <div className={classes.header}>
        <Text variant="caption1">Volume Backend Configurations</Text>
      </div>
      {params?.volumeTypes?.map((volumeType, idx) => (
        <Fragment key={idx}>
          <div className={classes.cell}>
            <div className={classes.sameLineCell}>
              <FontAwesomeIcon
                className={classes.remove}
                onClick={() => removeVolumeTypeFn(volumeType)}
                solid
              >
                circle-minus
              </FontAwesomeIcon>
              <TextField
                id={`volumeTypes.${idx}`}
                label=""
                onChange={(value) => updateVolumeTypeName(value, volumeType, idx)}
                value={volumeType}
                placeholder="Volume Type Name"
                required
              />
            </div>
          </div>
          <div className={classes.cell}>
            <VolumeBackendConfigurationCards
              params={params}
              updateParams={updateParams}
              getParamsUpdater={getParamsUpdater}
              volumeType={volumeType}
            />
          </div>
        </Fragment>
      ))}
      <div className={classes.cell}>
        <AddVolumeType
          params={params}
          updateParams={updateParams}
          getParamsUpdater={getParamsUpdater}
        />
      </div>
    </div>
  )
}

import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { remove, update } from 'ramda'
import CreateVolumeBackendConfigurationModal from './CreateVolumeBackendConfigurationModal'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'

const useStyles = makeStyles((theme: Theme) => ({
  cards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 12,
  },
  addConfigCard: {
    border: `1px solid ${theme.components.table.border}`,
    display: 'grid',
    padding: '16px',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addConfigCardBody: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 12,
    padding: '16px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  configCard: {
    border: `1px solid ${theme.components.table.border}`,
    display: 'grid',
    gridAutoFlow: 'row',
    gap: 12,
    padding: '16px',
  },
  cardRow: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: 'max-content max-content',
    alignItems: 'center',
  },
  cardName: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    alignItems: 'center',
  },
  editIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: 8,
  },
  removeIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

const driverLabels = {
  Ceph: 'Ceph',
  LVM: 'LVM',
  NFS: 'NFS',
  NetApp: 'NetApp Data ONTAP',
  PureStorageISCSI: 'Pure Storage ISCSI',
  PureStorageFC: 'Pure Storage FC',
  PureStorageNVME: 'Pure Storage NVME',
  HuaweiISCSI: 'Huawei Dorado ISCSI',
  HuaweiFC: 'Huawei Dorado FC',
  HPE3PARISCSI: 'HPE 3PAR ISCSI',
  HPE3PARFC: 'HPE 3PAR FC',
  Custom: 'Custom',
}

export default function VolumeBackendConfigurationCards({
  params,
  updateParams,
  getParamsUpdater,
  volumeType,
}) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedConfig, setSelectedConfig] = useState(null)
  const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
  const clickAddConfig = () => {
    setSelectedConfig(null)
    setModalOpen(true)
  }

  const editConfigFn = (config, idx) => {
    setSelectedConfig(config)
    setSelectedConfigIndex(idx)
    setModalOpen(true)
  }

  const updateConfigsFn = useCallback(
    (config) => {
      if (selectedConfigIndex !== null) {
        updateParams({
          volumeBackendConfigs: update(
            selectedConfigIndex,
            { ...selectedConfig, ...config },
            params.volumeBackendConfigs,
          ),
        })
      } else {
        updateParams({ volumeBackendConfigs: [...params.volumeBackendConfigs, config] })
      }
    },
    [selectedConfig, selectedConfigIndex, params.volumeBackendConfigs],
  )

  const removeConfigFn = useCallback(
    (network, idx) => {
      updateParams({ volumeBackendConfigs: remove(idx, 1, params.volumeBackendConfigs) })
    },
    [params.volumeBackendConfigs],
  )

  const closeModal = () => {
    setModalOpen(false)
    setSelectedConfig(null)
    setSelectedConfigIndex(null)
  }

  const filteredConfigs = useMemo(
    () => params.volumeBackendConfigs.filter((configs) => configs.volumeType === volumeType),
    [params.volumeBackendConfigs, volumeType],
  )

  return (
    <>
      {modalOpen && (
        <CreateVolumeBackendConfigurationModal
          volumeConfig={selectedConfig}
          currentVolumeConfigs={params.volumeBackendConfigs}
          updateVolumeConfigs={updateConfigsFn}
          onClose={closeModal}
          volumeType={volumeType}
        />
      )}
      <div className={classes.cards}>
        {filteredConfigs.map((config, idx) => (
          <div key={idx} className={classes.configCard}>
            <div className={classes.cardRow}>
              <div className={classes.cardName}>
                <FontAwesomeIcon
                  onClick={() => editConfigFn(config, idx)}
                  className={classes.editIcon}
                >
                  pen-to-square
                </FontAwesomeIcon>
                <Text variant="caption1">{config?.name}</Text>
              </div>
              <FontAwesomeIcon
                onClick={() => removeConfigFn(config, idx)}
                className={classes.removeIcon}
              >
                xmark
              </FontAwesomeIcon>
            </div>
            <div className={classes.cardRow}>
              <Text variant="body2">
                <b>Driver:</b> {driverLabels[config?.driver]}
              </Text>
            </div>
          </div>
        ))}
        <div className={classes.addConfigCard} onClick={clickAddConfig}>
          <div className={classes.addConfigCardBody}>
            <div>
              <FontAwesomeIcon size="xl">plus</FontAwesomeIcon>
            </div>
            <Text variant="caption1">Add Configuration</Text>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useMemo } from 'react'
import Alert from 'core/components/Alert'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import CodeBlock from 'core/components/CodeBlock'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import Info from 'core/components/validatedForm/Info'
import CopyToClipboard from 'core/components/CopyToClipboard'
import { sessionStoreKey, SessionState } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'

export default function AddServersPage({ props }) {
  const classes = useStyles()
  const selectSessionState = prop<string, SessionState>(sessionStoreKey)
  const session = useSelector(selectSessionState)
  const { activeRegion } = session
  const { getUserPrefs } = useScopedPreferences()
  const { currentTenant } = getUserPrefs(session?.username)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const tenant = useMemo(() => tenants.find((tenant) => tenant.id === currentTenant), [tenants])

  return (
    <div className={classes.root}>
      <Text variant="h3">Add a New Host</Text>
      <div className={classes.section}>
        <Text variant="subtitle2">Adding New Hosts</Text>
        <Alert
          title="In order to add your hosts, follow the steps below:"
          variant="primary"
          message={
            <div className={classes.tutorial}>
              <NumberedStepLabel
                step={1}
                title="Login to your host terminal"
                className={classes.tutorialStep}
              />
              <NumberedStepLabel
                step={2}
                title="Install the host agent by invoking below commands as root user:"
                className={classes.tutorialStep}
              />
              <div className={classes.codeBlocks}>
                <CopyToClipboard
                  copyText={`bash <(curl -s https://cloud-ctl.s3.us-west-1.amazonaws.com/cloud-ctl-setup)`}
                >
                  <CodeBlock>{`bash <(curl -s https://cloud-ctl.s3.us-west-1.amazonaws.com/cloud-ctl-setup)`}</CodeBlock>
                </CopyToClipboard>
                <br />
                <CopyToClipboard copyText="cloud-ctl prep-node">
                  <CodeBlock>cloud-ctl prep-node</CodeBlock>
                </CopyToClipboard>
              </div>
              <Text variant="body2">Here is your info for quick use:</Text>
              <Text component="div" variant="body2">
                Account URL:{' '}
                <CopyToClipboard copyText={window.location.origin} codeBlock={false}>
                  <span className={classes.linkText}>{window.location.origin}</span>
                </CopyToClipboard>
              </Text>
              <Text component="div" variant="body2">
                Username:{' '}
                <CopyToClipboard copyText={session?.username} codeBlock={false}>
                  <span className={classes.linkText}>{session?.username}</span>
                </CopyToClipboard>
              </Text>
              <Text component="div" variant="body2">
                Region:{' '}
                <CopyToClipboard copyText={activeRegion} codeBlock={false}>
                  <span className={classes.linkText}>{activeRegion}</span>
                </CopyToClipboard>
              </Text>
              <Text component="div" variant="body2">
                Tenant:{' '}
                <CopyToClipboard copyText={tenant?.name} codeBlock={false}>
                  <span className={classes.linkText}>{tenant?.name}</span>
                </CopyToClipboard>
              </Text>
            </div>
          }
        />
        <Text variant="body2">
          Once the host agent is installed, you will see the host in your list of{' '}
          <SimpleLink src={routes.openstack.hosts.path()}>Cluster Hosts</SimpleLink> waiting to be
          assigned a role.
        </Text>
        <Text variant="body2">
          <b>NOTE:</b> The host agent package is personalized for your account. Do not share it with
          anyone outside your organization.
        </Text>
      </div>
      <div className={classes.section}>
        <Info title="Supported Distributions and Prerequisites" expanded={false}>
          <Text variant="body2">We currently support the following:</Text>
          <ul>
            <li>Ubuntu 20.04 LTS and Ubuntu 22.04 LTS - 64-bit (x86_64)</li>
          </ul>
          <Text variant="body2">
            If your host operating system isn't supported, please reach out to support.
          </Text>
        </Info>
      </div>
      <div className={classes.section}>
        <Info title="Troubleshooting" expanded={false}>
          <Text variant="body2">Here are some quick tips if you don't see your host:</Text>
          <ul className={classes.list}>
            <li>
              Verify that the host has outbound network connectivity to the internet and to the
              OpenStack controller:
            </li>
            <li>
              <CodeBlock>ping www.google.com</CodeBlock>
            </li>
            <li>
              <CodeBlock>telnet www.google.com 443</CodeBlock>
            </li>
            <li>
              If you have a proxy server, make sure to configure the host agent to route traffic via
              the proxy:
            </li>
            <li>
              <CodeBlock>
                {`sudo bash <path to installer> --proxy=<proxy server>:<proxy port>`}
              </CodeBlock>
            </li>
            <li>Confirm that the host agent is running on your host:</li>
            <li>
              <CodeBlock>sudo service pf9-hostagent status</CodeBlock>
            </li>
            <li>Check the host agent log and the comms log for errors:</li>
            <li>
              <CodeBlock>
                sudo cat /var/log/pf9/hostagent.log /var/log/pf9/comms/comms.log
              </CodeBlock>
            </li>
          </ul>
          <Text variant="body2">
            If these tips do not resolve the issue, please contact us for support. We're here to
            help!
          </Text>
        </Info>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  tutorial: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  tutorialStep: {
    '& .title': {
      ...theme.typography.body2,
    },
  },
  codeBlocks: {
    paddingLeft: 40,
  },
  root: {
    display: 'grid',
    gap: 24,
  },
  section: {
    display: 'grid',
    gap: 12,
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingInlineStart: 25,
  },
  linkText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}))

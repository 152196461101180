import React, { useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick, prop } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { listImages, deleteImage } from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { imagesSelector } from './selectors'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import useReactRouter from 'use-react-router'
import { durationBetweenDates } from 'utils/misc'
import { humanReadableSize } from 'openstack/helpers'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import EditImageModal from './EditImageModal'
import EditImageMetadataModal from './EditImageMetadataModal'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { routes } from 'core/utils/routes'
import ImportImageModal from './ImportImageModal'
import AddImageModal from './AddImageModal'
import CreateButton from 'core/components/buttons/CreateButton'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { useSelector } from 'react-redux'

type ModelDataKey = DataKeys.Images
type SelectorModel = ArrayElement<ReturnType<typeof imagesSelector>>
type ActionParams = InferActionParams<typeof listImages>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Images', listTablePrefs)

const searchTargets = ['name', 'id']

const imageColumns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'visibility',
    label: 'Visibility',
  },
  {
    key: 'protected',
    label: 'Protected',
    render: (val) => (val ? 'True' : 'False'),
  },
  {
    key: 'disk_format',
    label: 'Disk Format',
  },
  {
    key: 'virtual_size',
    label: 'Virtual Disk Size',
    render: humanReadableSize,
  },
  {
    key: 'size',
    label: 'File Size',
    render: humanReadableSize,
  },
  {
    key: 'tags',
    label: 'Tags',
    render: (tags) => tags.join(', '),
  },
  {
    key: 'properties',
    label: 'Properties',
    disableSorting: true,
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
  {
    key: 'created_at',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
]

export default function ImagesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { history, location } = useReactRouter()
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'

  const [selectedImage, setSelectedImage] = useState<SelectorModel>(null)
  const [showEditImageModal, setShowEditImageModal] = useState<boolean>(false)
  const [showEditMetadataModal, setShowEditMetadataModal] = useState<boolean>(false)

  const { message, loading, reload } = useListAction(listImages, {
    params,
  })
  const data = useAppSelector(imagesSelector)

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      label: 'Edit',
      icon: 'edit',
      handleAction: ([image]) => {
        setSelectedImage(image)
        setShowEditImageModal(true)
      },
      refreshAfterSuccess: true,
    },
    {
      cond: ([image]) => {
        return !!image
      },
      label: 'Edit Properties',
      icon: 'tags',
      handleAction: ([image]) => {
        setSelectedImage(image)
        setShowEditMetadataModal(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Images" />
      <ImportImageModal addRoute={routes.openstack.importImage} />
      {!isVmware && <AddImageModal addRoute={routes.openstack.addImage} />}
      {showEditImageModal && (
        <EditImageModal onClose={() => setShowEditImageModal(false)} rows={[selectedImage]} />
      )}
      {showEditMetadataModal && (
        <EditImageMetadataModal
          onClose={() => setShowEditMetadataModal(false)}
          rows={[selectedImage]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Images}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={imageColumns}
        addUrl={isVmware ? null : routes.openstack.addImage.path()}
        addText={isVmware ? null : 'Add Image'}
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        deleteAction={deleteImage}
        extraHeaderContent={
          <CreateButton onClick={() => history.push(routes.openstack.importImage.path())}>
            Import With CLI
          </CreateButton>
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

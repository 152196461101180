import store from 'app/store'
import { keys } from 'ramda'

export const getPhysicalNetworkRequestBody = (params) => {
  if (params.networkType === 'flat') {
    return {
      network: {
        name: params.name,
        description: params.description,
        admin_state_up: params.adminState === 'Up',
        'provider:network_type': params.networkType,
        'provider:physical_network': params.networkLabel,
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  } else if (params.networkType === 'vlan') {
    return {
      network: {
        name: params.name,
        description: params.description,
        admin_state_up: params.adminState === 'Up',
        'provider:network_type': params.networkType,
        'provider:physical_network': params.networkLabel,
        'provider:segmentation_id': params.segmentationId,
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  } else if (params.networkType === 'vxlan') {
    return {
      network: {
        name: params.name,
        description: params.description,
        admin_state_up: params.adminState === 'Up',
        'provider:network_type': params.networkType,
        'provider:segmentation_id': params.segmentationId,
        'router:external': !!params.external,
        shared: !!params.shared,
      },
    }
  }
  return {}
}

export const getNetworkRequestBody = (params, hostConfigs) => {
  const underlayNetworkType = store.getState()?.session?.underlayNetwork?.type || 'vlan'
  // If underlay network type is vlan, then all of the host configs
  // should have same underlay network label, just look at the first
  const firstHostConfig = hostConfigs?.[0]
  const tunnelPhysnet = firstHostConfig?.tunnelingInterface
  const labelKeys = keys(firstHostConfig?.networkLabels)
  const tunnelLabel = labelKeys?.find((key) => {
    return firstHostConfig?.networkLabels?.[key] === tunnelPhysnet
  })
  const underlayNetworkLabel = underlayNetworkType === 'vlan' ? tunnelLabel : ''
  if (params.networkType === 'tenant') {
    return {
      network: {
        name: params.name,
        description: params.description,
        admin_state_up: params.adminState !== 'Down',
        shared: params.shared,
        'provider:network_type': underlayNetworkType,
        // TODO - Remove the hardcoding for provider:physical_network
        ...(underlayNetworkType === 'vlan' && {
          'provider:physical_network': underlayNetworkLabel,
        }),
      },
    }
  } else if (params.networkType === 'provider') {
    // todo
    return {
      network: {
        name: params.name,
        description: params.description,
        admin_state_up: params.adminState !== 'Down',
        shared: params.shared,
      },
    }
  } else if (params.networkType === 'external') {
    return {
      network: {
        name: params.name,
        description: params.description,
        admin_state_up: params.adminState !== 'Down',
        shared: params.shared,
        'provider:network_type': params.externalNetworkType,
        'provider:physical_network': 'external', // presumed value
        'provider:segmentation_id':
          params.externalNetworkType === 'vlan' ? params.segmentationId : undefined,
        'router:external': true,
      },
    }
  }
  return {}
}
